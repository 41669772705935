<!-- 厂家供货表 -->
<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20">
      <!-- 模版名称 -->
      <FormItem>
        <span>模版名称：</span>
        <Input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.template_name" />
      </FormItem>
      <!-- 按钮 -->
      <FormItem class="po-create-number marginRight0" :label-width="10">
        <span class="pageBtn finger btnSure" @click="query()">查询</span>
        <span class="pageBtn finger btnReset marginLeft20" @click="Add()">新增</span>
      </FormItem>
    </Form>

    <!-- 新增 -->
    <Modal v-model="status" :title="title" cancel-text @on-cancel="Cancle" width="568">
      <div class="clearfix paddingBot40px">
        <div class="item">
          <span class="itemTitle">模版名称：</span>
          <i-input v-model="form.template_name" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <div class="item">
          <span class="itemTitle">模版类型：</span>
          <Select placeholder="请选择" v-model="form.template_type" filterable class="iviewIptWidth307" @on-change="typeChange">
            <Option v-for="(item, index) in TemplateList" :value="item.typeID" :key="index">{{ item.typeName }}</Option>
          </Select>
          <span class="longTextBtn finger btnSure" style="margin-left: 20px;" @click="showTypeFont">字段对照</span>
        </div>
        <div class="item">
          <span class="itemTitle">模版上传：</span>
          <!-- doc.docx.xls. -->
          <i-input readonly v-model="file_name" placeholder="仅支持xlsx格式的文件" class="iviewIptWidth307">
            <span slot="append" class="finger">
              <NewFormUploadFile label="上传" name="files" :multiple="false" :apiSrc="apiSrc" :url="apiSrc" @get-data="importTemplate"></NewFormUploadFile>
            </span>
          </i-input>
        </div>
      </div>
      <div slot="footer">
        <span v-if="title === '新增模版'" class="pageBtn finger btnSure" @click="SureAdd()">确定</span>
        <span v-if="title === '修改'" class="pageBtn finger btnSure" @click="SureUpdate()">确定</span>
      </div>
    </Modal>
    <Modal v-model="fontStatus" title="字段查询" cancel-text @on-cancel="fontStatus = false" width="568">
      <Table :productList="typeColumns" :productData="typeList" border class="table" height="500"></Table>
    </Modal>
    <!-- 提示弹窗 -->
    <!-- <Modal v-model="tipStatus" label-position="left" width="400" :footer-hide="true" :mask-closable="false" :closable="false" class-name="vertical-center-modal">
      <div class="topModal clearfix">
        <div v-if="showName === 'success'" class="content">
          <img src="@/assets/images/tipsSuccessImg.png" class="tipsImg fl" />
          <div class="flDiv fl">
            <p>新增模版成功！</p>
          </div>
        </div>
        <div v-if="showName === 'Update'" class="content">
          <img src="@/assets/images/tipsSuccessImg.png" class="tipsImg fl" />
          <div class="flDiv fl">
            <p>修改模版成功！</p>
          </div>
        </div>
        <div v-if="showName === 'Delete'" class="content">
          <img src="@/assets/images/tipsSuccessImg.png" class="tipsImg fl" />
          <div class="flDiv fl">
            <p>删除模版成功！</p>
          </div>
        </div>
        <div v-if="showName === 'fail'" class="content">
          <img src="@/assets/images/tipsDeleteImg.png" class="tipsImg fl" />
          <div class="flDiv fl">
            <p>新增模版失败！</p>
          </div>
        </div>
      </div>
      <div class="foot">
        <span class="pageBtn finger marginLeft20 btnSure" @click="tipStatus = false">确定</span>
      </div>
    </Modal> -->
    <!-- 删除弹窗 -->
    <tips-component showModal="delete" v-if="Isdelet" @cancleBtn="Isdelet = false" @sureBrn="SureDel"></tips-component>
    <Table :productList="listColumns" :productData="listData" border class="table" :loading="isLoad" :isLoad="isLoad"></Table>
    <template v-if="listData.length != 0">
      <div class="marginTop20">
        <Page v-if="total <= 60" :total="total" :current="pages.page" @on-change="changePage" class="fl page" />
        <Page v-if="total > 60" show-elevator :total="total" :current="pages.page" @on-change="changePage" class="fl page" />
        <div class="fr totalFont page">共计{{ total }}条记录</div>
      </div>
    </template>
  </div>
</template>

<script>
import Table from '@/components/table'
import NewFormUploadFile from '@/components/NewformUploadFile'
import TipsComponent from '@/components/tipsComponent'
import http from '@/service/baseUrl'
export default {
  name: 'PrintTemplete',
  components: {
    Table,
    NewFormUploadFile,
    TipsComponent,
  },
  data() {
    return {
      isLoad: true,
      fontStatus: false,
      status: false,
      // tipStatus: false,
      Isdelet: false,
      showName: '',
      title: '',
      // 接口地址
      apiSrc: '',
      options: {},
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 75,
          maxWidth: 90,
          key: 'index',
        },
        {
          title: '模版名称',
          key: 'template_name',
          align: 'center',
          width: 431,
        },
        {
          title: '模版类型',
          key: 'template_type_str',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '最后一次更新时间',
          key: 'update_time',
          align: 'center',
          width: 434,
          render: (h, param) => {
            if (this.$moment.unix(param.row.update_time).format('YYYY-MM-DD HH:mm:ss') !== '1970-01-01 08:00:00') {
              return h('div', [
                h(
                  'span',
                  {
                    class: 'tableBlakcFont',
                  },
                  this.$moment.unix(param.row.update_time).format('YYYY-MM-DD HH:mm:ss')
                ),
              ])
            } else {
              return h('div', [
                h(
                  'span',
                  {
                    class: 'tableBlakcFont',
                  },
                  ''
                ),
              ])
            }
          },
        },
        {
          title: '最后一次操作用户',
          key: 'user_name',
          align: 'center',
          width: 321,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                param.row.user_name
              ),
            ])
          },
        },
        {
          title: '操作',
          align: 'center',
          minWidth: 100,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    fontSize: '14px',
                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      console.log(param.row)
                      this.form.template_name = param.row.template_name
                      this.form.template_type = param.row.template_type
                      this.form.template_url = param.row.template_url
                      this.file_name = param.row.template_url.file_name
                      this.UpdateId.template_id = param.row.template_id
                      this.Update()
                    },
                  },
                },
                '修改'
              ),
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    fontSize: '14px',
                    marginLeft: '20px',
                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      console.log(param.row.template_id)
                      this.DeletId.template_id = param.row.template_id
                      this.Isdelet = true
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      // 更新id
      UpdateId: { template_id: '' },
      // 删除id
      DeletId: { template_id: '' },
      TemplateList: [
        { typeName: '采购单', typeID: 1 },
        { typeName: '收货验收单', typeID: 2 },
        { typeName: '入库单', typeID: 3 },
        { typeName: '销售单', typeID: 4 },
        { typeName: '拣货单', typeID: 5 },
        { typeName: '出库单', typeID: 6 },
        { typeName: '采购记录', typeID: 7 },
        { typeName: '收货验收记录', typeID: 8 },
        { typeName: '入库记录', typeID: 9 },
        { typeName: '销售记录', typeID: 10 },
        { typeName: '拣货记录', typeID: 11 },
        { typeName: '出库记录', typeID: 12 },
        { typeName: '调拨单', typeID: 13 },
      ],
      // 查询参数
      queryFrom: {
        template_name: '',
      },
      // 新增弹窗列表数据
      form: {
        template_name: '',
        template_type: '',
        template_url: '',
      },
      file_name: '',
      pages: {
        page: 1,
        rows: 10,
      },
      total: 0,
      typeColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 80,
        },
        {
          title: '字段名称',
          key: 'title',
          align: 'center',
        },
        {
          title: '参数值',
          key: 'data_column',
          align: 'center',
        },
      ],
      typeList: [],
    }
  },
  mounted() {
    this.getPrintTempleteList()
    this.apiSrc = http.http + this.$api.templateUpload.url
  },
  methods: {
    typeChange(e) {
      if (!e) return
      this.$http.get(this.$api.templateColumn, { template_type: e }, false).then(res => {
        this.typeList = res.data
      })
    },
    showTypeFont() {
      if (!this.form.template_type) {
        this.$Message.warning('请先选择模版类型')
        return
      }
      this.fontStatus = true
    },
    // 模版上传
    importTemplate(data) {
      this.form.template_url = data
      this.file_name = data.file_name
    },
    // 点击新增按钮
    Add() {
      this.status = true
      this.title = '新增模版'
    },
    Update() {
      this.typeChange(this.form.template_type)
      this.status = true
      this.title = '修改'
    },
    // 新增确认
    SureAdd() {
      let data = {}
      let form = this.form
      Object.assign(data, form)
      console.log(data)
      if (data.template_name === '') {
        this.$Message.warning('请完善模版信息！')
        return
      }
      var regu = '^[ ]+$'
      var re = new RegExp(regu)
      var state = re.test(data.template_name)
      if (state || !data.template_type || !data.template_url) {
        this.$Message.warning('请完善模版信息！')
        return
      }
      this.$http.post(this.$api.templateManage, data, true).then(res => {
        // this.showName = 'success'
        this.$Message.success('新增模版成功！')
        // this.tipStatus = true
        this.status = false
        this.form = {
          template_name: '',
          template_type: '',
          template_url: '',
        }
        this.file_name = ''
        this.getPrintTempleteList()
      })
    },
    // 修改确认
    SureUpdate() {
      let data = {}
      let form = this.form
      Object.assign(data, form, this.UpdateId)
      console.log(data)
      if (data.template_name === '') {
        this.$Message.warning('请完善模版信息！')
        return
      }
      var regu = '^[ ]+$'
      var re = new RegExp(regu)
      var state = re.test(data.template_name)
      if (state || !data.template_type || !data.template_url) {
        this.$Message.warning('请完善模版信息！')
        return
      }
      this.$http.put(this.$api.templateManage, data, true).then(res => {
        // this.showName = 'Update'
        this.$Message.success('修改模版成功！')
        // this.tipStatus = true
        this.status = false
        this.form = {
          template_name: '',
          template_type: '',
          template_url: '',
        }
        this.file_name = ''
        this.getPrintTempleteList()
      })
    },
    // 确认删除
    SureDel() {
      this.$http.DeleteAll(this.$api.templateManage, this.DeletId, true).then(res => {
        // this.showName = 'Delete'
        this.$Message.success('删除模版成功！')
        // this.tipStatus = true
        this.Isdelet = false
        this.getPrintTempleteList()
      })
    },
    // 取消
    Cancle() {
      this.form = {
        template_name: '',
        template_type: '',
        template_url: '',
      }
      this.file_name = ''
      this.status = false
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.queryFrom.begin_time = e
        that.queryFrom.end_time = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.begin_time)
          },
        }
      } else {
        this.queryFrom.end_time = e
      }
    },
    // 点击查询
    query() {
      this.pages.page = 1
      this.getPrintTempleteList()
    },
    // 页码改变
    changePage(e) {
      this.pages.page = e
      this.getPrintTempleteList()
    },
    // 获取打印模版列表
    getPrintTempleteList() {
      let data = {
        page: this.pages.page, // 页
        rows: this.pages.rows, // 行
        template_name: this.queryFrom.template_name,
      }
      this.isLoad = true
      this.$http.get(this.$api.templateManage, data, true).then(res => {
        this.isLoad = false
        this.listData = res.data
        this.total = res.total
      })
    },
  },
}
</script>

<style scoped lang="less">
.paddingBot40px {
  padding-bottom: 40px;
}
.item {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;
  .itemTitle {
    width: 100px;
    color: #000;
    text-align: justify;
    height: 32px;
    line-height: 32px;
  }
  .itemTitle::after {
    content: '';
    display: inline-block;
    padding-left: 100%;
  }
}
.marginTop20 {
  margin-top: 20px;
}
.table {
  /deep/ .ivu-table-summary {
    border-top: 0;
  }
}
.modalContent {
  padding-top: 31px;
  padding-bottom: 30px;
}
.modaltitle {
  margin-bottom: 13px;
  height: 22px;
  font-size: 16px;
  //font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 22px;
}
.foot {
  text-align: right;
}
.topModal {
  padding-bottom: 30px;
  margin-top: 30px;

  .content {
    display: flex;
    align-items: center;
    .tipsImg {
      width: 36px;
      height: 36px;
      margin: 0 13px 0 9px;
    }
    .flDiv {
      p:nth-child(1) {
        display: block;
        height: 24px;
        font-size: 18px;
        font-weight: 600;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
      }
    }
  }
}
</style>
